<template>
  <BaseModular subTitle="CA办理支付" title="">
    <div class="base">
      <div class="amount">
        <span>订单金额：</span>
        <span>￥{{ amount }}</span>
      </div>
      <div class="pay-type">
        <span>支付方式：</span>
        <span>
          <el-radio-group
            v-model="payType"
            @change="changePayType"
            class="pay-radio"
          >
            <el-radio label="wxpay"
              ><img class="pay-icon" src="@/static/Pay/wxpay_logo.png" alt=""
            /></el-radio>
            <el-radio label="alipay"
              ><img class="pay-icon" src="@/static/Pay/alipay_logo.png" alt=""
            /></el-radio>
          </el-radio-group>
        </span>
      </div>
      <div class="apply">
        <el-button type="primary" size="large" @click="genPayOrder"
          >支付</el-button
        >
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      class="pay-dialog"
      title="扫码支付"
      width="30%"
    >
      <img :src="codeUrl" class="qrcode" />
      <p>请打开手机支付软件扫一扫付款</p>
      <p class="amount">金额：￥{{ amount }}</p>
    </el-dialog>
  </BaseModular>
</template>
<script >
import { reactive, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { ElMessageBox } from "element-plus";
import { caApplyStore } from "@/store";
import { pay, checkOrderStatus } from "@/api/caApply/caApply";
import BaseModular from "@/components/BaseModular/BaseModular";

export default {
  components: { BaseModular },
  setup() {
    const caStore = caApplyStore();
    const setData = (type, value) => {
      caStore.setData(type, value);
    };
    const applyId = computed(() => storeToRefs(caStore).applyId);
    const amount = computed(() => storeToRefs(caStore).amount);
    const dialogVisible = computed(() => storeToRefs(caStore).dialogVisible);
    const payType = computed(() => storeToRefs(caStore).payType);
    const orderNo = computed(() => storeToRefs(caStore).orderNo);
    const codeUrl = computed(() => storeToRefs(caStore).codeUrl);
    const timer = computed(() => storeToRefs(caStore).timer);
    // const { applyId, amount } = storeToRefs(caStore);
    // const dialogVisible = ref(false);
    // const payType = ref("wxpay");
    // const orderNo = ref("");
    // const codeUrl = ref("");
    // const timer = reactive(null);
    const data = reactive({
      dialogVisible,
      payType,
      orderNo,
      codeUrl,
      timer,
      applyId,
      amount,
    });

    const changePayType = (value) => {
      console.log(value);
      setData("payType", value);
    };
    const genPayOrder = () => {
      console.log(data, applyId, applyId.value);
      pay({ id: applyId.value.value, payType: payType.value.value }).then(
        (rsp) => {
          const timer = setInterval(() => {
            checkOrder();
          }, 2000);
          setData("orderNo", rsp.data.orderNo);
          setData("codeUrl", window.location.origin + "/" + rsp.data.url);
          setData("dialogVisible", true);
          setData("orderNo", rsp.data.orderNo);
          setData("timer", timer);
        }
      );
    };
    const checkOrder = () => {
      checkOrderStatus({
        orderNo: orderNo.value.value,
      }).then((res) => {
        if (res.data.paySuccess) {
          console.log(timer.value.value, "timer.value.value");
          clearInterval(timer.value.value);
          ElMessageBox.alert("您的订单将会由相关人员处理", "支付成功", {
            confirmButtonText: "确定",
            callback: () => {
              closeDialog();
              window.open(
                process.env.VUE_APP_SEVERCE_PLATFORM + "/#/ca/caapply",
                "_blank"
              );
            },
          });
        }
      });
    };
    const closeDialog = () => {
      setData("orderNo", "");
      setData("codeUrl", "");
      setData("dialogVisible", false);
      caStore.$reset();
    };
    return {
      genPayOrder,
      ...data,
      closeDialog,
      checkOrder,
      changePayType,
      setData,
    };
  },
};
</script>


<style scoped>
@import url("./CaApplyPay.css");
</style>
  