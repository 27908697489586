<template>
  <BaseModular subTitle="CA办理申请" title="">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-width="80px"
    >
      <el-divider content-position="center">
        <span class="Ca_title">联系人信息</span>
      </el-divider>
      <el-row :gutter="30">
        <el-col :offset="1" :span="11">
          <el-form-item label="真实姓名" prop="userRealName">
            <el-input
              placeholder="真实姓名"
              v-model="formData.userRealName"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在地" prop="userAreaCode" required>
            <el-select
              placeholder="省"
              v-model="formData.userProvince"
              class="region"
              @change="(val) => getCity('user', val)"
            >
              <el-option
                v-for="item in province"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
            <el-select
              placeholder="市"
              v-model="formData.userCity"
              class="region"
              @change="(val) => getArea('user', val)"
            >
              <el-option
                v-for="item in userCityList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
            <el-select
              placeholder="区县"
              v-model="formData.userAreaCode"
              class="region"
            >
              <el-option
                v-for="item in userAreaList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="userPhone">
            <el-input
              placeholder="联系电话"
              v-model="formData.userPhone"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址" prop="userAddress">
            <el-input
              placeholder="邮寄地址"
              v-model="formData.userAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="right">
          <el-form-item prop="userIdCardFiles" class="attach">
            <el-upload
              :file-list="formData.userIdCardFiles"
              list-type="picture-card"
              accept=".JPEG,.PNG,.GIF"
              action="/api/blade-resource/oss/endpoint/put-file"
              :headers="getHeaders()"
              :limit="1"
              :on-exceed="onExceed"
              :on-preview="handlePictureCardPreview"
              :on-success="onIdCardSuccess"
              :on-remove="onIdCardRemove"
              :before-upload="checkfileType"
            >
              <el-icon>
                <Plus />
              </el-icon>
              <template #tip>
                <div class="el-upload__tip">
                  经办人身份证复印件（需加盖公章）
                </div>
                <div class="el-upload__tip">
                  严禁上传包含色情、暴力、反动等相关违法信息的文件。
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="center">
        <span class="Ca_title">公司信息</span>
      </el-divider>
      <el-row :gutter="30">
        <el-col :offset="1" :span="11">
          <el-form-item label="公司名称" prop="companyName">
            <el-input
              placeholder="公司名称"
              v-model="formData.companyName"
            ></el-input>
          </el-form-item>
          <el-form-item label="办公地址" prop="companyAreaCode" required>
            <el-select
              placeholder="省"
              v-model="formData.companyProvince"
              class="region"
              @change="(val) => getCity('company', val)"
            >
              <el-option
                v-for="item in province"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
            <el-select
              placeholder="市"
              v-model="formData.companyCity"
              class="region"
              @change="(val) => getArea('company', val)"
            >
              <el-option
                v-for="item in companyCityList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
            <el-select
              placeholder="区县"
              v-model="formData.companyAreaCode"
              class="region"
            >
              <el-option
                v-for="item in companyAreaList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="办公电话" prop="companyPhone">
            <el-input
              placeholder="办公电话"
              v-model="formData.companyPhone"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="companyAddress">
            <el-input
              placeholder="详细地址"
              v-model="formData.companyAddress"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input
              placeholder="电子邮箱"
              v-model="formData.email"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="right">
          <el-form-item prop="certificateFiles" class="attach">
            <el-upload
              :file-list="formData.certificateFiles"
              list-type="picture-card"
              accept=".JPEG,.PNG,.GIF"
              action="/api/blade-resource/oss/endpoint/put-file"
              :limit="1"
              :on-exceed="onExceed"
              :headers="getHeaders()"
              :on-preview="handlePictureCardPreview"
              :on-success="onCertificateSuccess"
              :on-remove="onCertificateRemove"
              :before-upload="checkfileType"
            >
              <el-icon>
                <Plus />
              </el-icon>
              <template #tip>
                <div class="el-upload__tip">
                  单位营业执照副本复印件（需加盖公章）
                </div>
                <div class="el-upload__tip">
                  严禁上传包含色情、暴力、反动等相关违法信息的文件。
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="center">附件信息</el-divider>
      <el-row :gutter="30">
        <el-col :offset="1" :span="11">
          <el-form-item class="attach">
            <el-button
              class="file"
              icon="el-icon-download"
              @click="
                () => {
                  downloadFile(
                    getFileUrl(applyGuide.caDigitalFile.name),
                    applyGuide.caDigitalFile.originalName
                  );
                }
              "
            >
              《CA数字证书申请表》模板下载</el-button
            >
          </el-form-item>
          <el-form-item prop="caDigitalFiles" class="attach">
            <el-upload
              class="upload"
              drag
              multiple
              action="/api/blade-resource/oss/endpoint/put-file"
              :headers="getHeaders()"
              :file-list="formData.caDigitalFiles"
              :on-success="onCaDigitalSuccess"
              :on-remove="onCaDigitalRemove"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                《CA数字证书申请表》（加盖公章）
              </div>
              <div class="el-upload__text"><em>点击或拖动文件上传</em></div>
              <div class="el-upload__text">支持单个或批量上传。</div>
              <div class="el-upload__text">
                严禁上传包含色情、暴力、反动等相关违法信息的文件。
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item class="attach">
            <el-button
              class="file"
              icon="el-icon-download"
              @click="
                () => {
                  downloadFile(
                    getFileUrl(applyGuide.signatureFile.name),
                    applyGuide.signatureFile.originalName
                  );
                }
              "
            >
              《电子签章采集表》模板下载</el-button
            >
          </el-form-item>
          <el-form-item prop="signatureFiles" class="attach">
            <el-upload
              class="upload"
              drag
              multiple
              action="/api/blade-resource/oss/endpoint/put-file"
              :headers="getHeaders()"
              :file-list="formData.signatureFiles"
              :on-success="onSignatureSuccess"
              :on-remove="onSignatureRemove"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">《电子签章采集表》</div>
              <div class="el-upload__text"><em>点击或拖动文件上传</em></div>
              <div class="el-upload__text">支持单个或批量上传。</div>
              <div class="el-upload__text">
                严禁上传包含色情、暴力、反动等相关违法信息的文件。
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="center">办理方式</el-divider>
      <el-row :gutter="30" class="center">
        <el-form-item label="办理方式" prop="receiveType">
          <el-select
            v-model="formData.receiveType"
            placeholder="办理方式"
            class="max-width"
          >
            <el-option label="邮寄" value="0"></el-option>
            <el-option label="现场领取" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row :gutter="30" class="center">
        <el-form-item label="办理年限" prop="handlePeriod">
          <el-select
            v-model="formData.handlePeriod"
            placeholder="办理方式"
            class="max-width"
          >
            <el-option label="1年" value="1"></el-option>
            <el-option label="2年" value="2"></el-option>
            <el-option label="3年" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row :gutter="30" class="center captcha">
        <el-form-item label="验证码" prop="captcha">
          <el-input v-model="formData.captcha"></el-input>
        </el-form-item>
        <img :src="imgCode.image" style="cursor: pointer" @click="reLoadImg" />
      </el-row>
      <el-row :gutter="30" class="center option">
        <el-form-item>
          <el-button class="submit" @click="submitForm(formRef)"
            >提交</el-button
          >
          <el-button @click="store.changeView(0)">取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </BaseModular>
  <el-dialog v-model="imageDialog.visible">
    <img class="image-dialog" :src="imageDialog.url" alt="Preview Image" />
  </el-dialog>
</template>

<script>
import { reactive, ref, h, computed, onBeforeMount } from "vue";
import { caApplyStore } from "@/store";
import { storeToRefs } from "pinia";
import { ElMessage, ElMessageBox } from "element-plus";
import BaseModular from "@/components/BaseModular/BaseModular";
import { getHeaders, getFileUrl } from "@/util/index";
import { getRegion, downloadFile, saveApply } from "@/api/caApply/caApply";

export default {
  components: { BaseModular },
  setup() {
    const cardUpload = ref(null);
    const store = caApplyStore();
    const { viewModel, applyGuide, applyId, amount } = storeToRefs(store);
    const imgCode = computed(() => storeToRefs(store).imgCode);
    const reLoadImg = store.loadImgCode;
    const formRef = ref(null);
    const imageDialog = reactive({
      visible: false,
      url: "",
    });
    const validUserRegion = (rule, value, callback) => {
      if (formData.userProvince === "") {
        callback(new Error("请选择省份"));
      }
      if (formData.userCity === "") {
        callback(new Error("请选择城市"));
      }
      if (formData.userAreaCode === "") {
        callback(new Error("请选择区/县"));
      }
      callback();
    };
    const checkPhone = (rule, value, callback) => {
      const tel = formData.userPhone;
      console.log(tel);
      const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      const phoneReg = reg.test(tel);
      if (!phoneReg) {
        callback(new Error("请输入正确的手机号格式"));
      }
      callback();
    };

    const checkTel = (rule, value, callback) => {
      const reg = /^[0-9]{7,12}$/;
      const tel = formData.companyPhone;
      var telReg = reg.test(tel);
      if (!telReg) {
        callback(new Error("请输入正确的办公电话格式"));
      }
      callback();
    };
    const validCompanyRegion = (rule, value, callback) => {
      if (formData.companyProvince === "") {
        callback(new Error("请选择省份"));
      }
      if (formData.companyCity === "") {
        callback(new Error("请选择城市"));
      }
      if (formData.companyAreaCode === "") {
        callback(new Error("请选择区/县"));
      }
      callback();
    };
    const formRules = reactive({
      userRealName: [
        { required: true, message: "请输入真实姓名", trigger: "blur" },
      ],
      userAreaCode: [
        { required: true, validator: validUserRegion, trigger: "change" },
      ],
      // userPhone: [
      //   { required: true, message: "请输入联系电话", trigger: "blur" },
      // ],
      userPhone: [{ required: true, validator: checkPhone, trigger: "change" }],
      userAddress: [
        { required: true, message: "请输入邮寄地址", trigger: "blur" },
      ],
      userIdCardFiles: [
        {
          type: "array",
          required: true,
          message: "请上传经办人身份证复印件",
          trigger: "blur",
        },
      ],
      companyName: [
        { required: true, message: "请输入公司名称", trigger: "blur" },
      ],
      companyAreaCode: [
        { required: true, validator: validCompanyRegion, trigger: "change" },
      ],
      // companyPhone: [
      //   { required: true, message: "请输入办公电话", trigger: "blur" },
      // ],
      companyPhone: [{ required: true, validator: checkTel, trigger: "change" }],
      companyAddress: [
        { required: true, message: "请输入详细地址", trigger: "blur" },
      ],
      email: [
        { required: true, message: "请输入电子邮箱", trigger: "blur" },
        {
          type: "email",
          message: "请输入正确的邮箱地址",
          trigger: ["blur", "change"],
        },
      ],
      certificateFiles: [
        {
          type: "array",
          required: true,
          message: "请上传单位营业执照副本复印件",
          trigger: "blur",
        },
      ],
      caDigitalFiles: [
        {
          type: "array",
          required: true,
          message: "请上传CA数字证书申请表",
          trigger: "blur",
        },
      ],
      signatureFiles: [
        {
          type: "array",
          required: true,
          message: "请上传电子签章采集表",
          trigger: "blur",
        },
      ],
      receiveType: [
        { required: true, message: "请选择办理方式", trigger: "change" },
      ],
      handlePeriod: [
        { required: true, message: "请选择办理年限", trigger: "change" },
      ],
      captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    });

    const province = reactive([]);
    const userCityList = reactive([]);
    const userAreaList = reactive([]);
    const companyCityList = reactive([]);
    const companyAreaList = reactive([]);
    const formData = reactive({
      userRealName: "",
      userProvince: "",
      userCity: "",
      userAreaCode: "",
      userPhone: "",
      userAddress: "",
      userIdCardFiles: [],
      companyName: "",
      companyProvince: "",
      companyCity: "",
      companyAreaCode: "",
      companyAddress: "",
      companyPhone: "",
      email: "",
      certificateFiles: [],
      caDigitalFiles: [],
      signatureFiles: [],
      receiveType: null,
      handlePeriod: null,
      captcha: "",
    });

    const changeView = (view) => {
      store.changeView(view);
    };
    const getProvince = () => {
      getRegion({ parentCode: "00" }).then((rsp) => {
        province.push(...rsp.data);
      });
    };
    const getCity = (type, parentCode) => {
      if (
        parentCode === null ||
        parentCode === undefined ||
        parentCode === ""
      ) {
        return;
      }
      getRegion({ parentCode: parentCode }).then((rsp) => {
        if (type === "user") {
          formData.userCity = "";
          userCityList.splice(0);
          userCityList.push(...rsp.data);
          formData.userAreaCode = "";
          userAreaList.splice(0);
        } else {
          formData.companyCity = "";
          companyCityList.splice(0);
          companyCityList.push(...rsp.data);
          formData.companyAreaCode = "";
          companyAreaList.splice(0);
        }
      });
    };
    const getArea = (type, parentCode) => {
      if (
        parentCode === null ||
        parentCode === undefined ||
        parentCode === ""
      ) {
        return;
      }
      getRegion({ parentCode: parentCode }).then((rsp) => {
        if (type === "user") {
          formData.userAreaCode = "";
          userAreaList.splice(0);
          userAreaList.push(...rsp.data);
        } else {
          formData.companyAreaCode = "";
          companyAreaList.splice(0);
          companyAreaList.push(...rsp.data);
        }
      });
    };
    const toElList = (arr) => {
      arr.forEach((item) => {
        item.url = getFileUrl(item.name);
        item.pathName = item.name;
        item.name = item.originalName;
      });
    };
    const toSubmit = (arr) => {
      const newArr = arr;
      newArr.forEach((item) => {
        item.name = item.pathName;
        item.status = 0;
      });
      return newArr;
    };
    const onExceed = () => {
      ElMessage({
        type: "error",
        message: "当前支持最大上传文件个数为 1 个!",
      });
    };
    const handlePictureCardPreview = (uploadFile) => {
      imageDialog.url = uploadFile.url;
      imageDialog.visible = true;
    };
    const onIdCardSuccess = (response, uploadFile, uploadFiles) => {
      console.log(response, uploadFile, uploadFiles, "response");
      const item = response.data;
      // const fileType = item.name.toLocaleUpperCase();
      // if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(ths.value)) {

      item.url = getFileUrl(item.name);
      item.pathName = item.name;
      item.name = item.originalName;
      formData.userIdCardFiles.push(item);
    };
    const onIdCardRemove = (uploadFile, uploadFiles) => {
      formData.userIdCardFiles = formData.userIdCardFiles.filter(
        (item) => item.name != uploadFile.name
      );
    };
    const onCertificateSuccess = (response, uploadFile, uploadFiles) => {
      const item = response.data;
      item.url = getFileUrl(item.name);
      item.pathName = item.name;
      item.name = item.originalName;
      formData.certificateFiles.push(item);
    };
    const onCertificateRemove = (uploadFile, uploadFiles) => {
      formData.certificateFiles = formData.certificateFiles.filter(
        (item) => item.name != uploadFile.name
      );
    };
    const onCaDigitalSuccess = (response, uploadFile, uploadFiles) => {
      const item = response.data;
      item.url = getFileUrl(item.name);
      item.pathName = item.name;
      item.name = item.originalName;
      formData.caDigitalFiles.push(item);
    };
    const onCaDigitalRemove = (uploadFile, uploadFiles) => {
      formData.caDigitalFiles = formData.caDigitalFiles.filter(
        (item) => item.name != uploadFile.name
      );
    };
    const onSignatureSuccess = (response, uploadFile, uploadFiles) => {
      const item = response.data;
      item.url = getFileUrl(item.name);
      item.pathName = item.name;
      item.name = item.originalName;
      formData.signatureFiles.push(item);
    };
    const onSignatureRemove = (uploadFile, uploadFiles) => {
      formData.signatureFiles = formData.signatureFiles.filter(
        (item) => item.name != uploadFile.name
      );
    };
    const submitForm = (form) => {
      form.validate((valid) => {
        if (valid) {
          const code = storeToRefs(store).imgCode.value.code;
          if (code.toLowerCase() !== formData.captcha.toLowerCase()) {
            ElMessage({
              type: "error",
              message: "验证码错误，请重新输入",
            });
            reLoadImg();
            return;
          }
          formData.userIdCardFiles = toSubmit(formData.userIdCardFiles);
          formData.certificateFiles = toSubmit(formData.certificateFiles);
          formData.caDigitalFiles = toSubmit(formData.caDigitalFiles);
          formData.signatureFiles = toSubmit(formData.signatureFiles);
          console.log(formData, "formData");
          ElMessageBox({
            title: "是否确认该订单？",
            message: h("div", null, [
              h(
                "p",
                null,
                "您选择的领取方式为:" +
                  (formData.receiveType == 0 ? "邮寄" : "现场领取")
              ),
              formData.receiveType == 0
                ? h("p", null, "邮寄地址为：" + formData.userAddress)
                : h("p", null, "领取地址为：海门区行政审批中心88号窗口"),
            ]),
            confirmButtonText: "确定支付",
            cancelButtonText: "取消修改",
            showCancelButton: true,
            callback: (action) => {
              if (action === "confirm") {
                saveApply(formData).then((res) => {
                  ElMessage({
                    type: "success",
                    message: "申请成功",
                  });
                  store.setData("applyId", res.data.id);
                  store.setData("amount", res.data.amount);
                  // applyId = res.data.id;
                  // amount = res.data.amount;
                  store.changeView(2);
                });
              }
            },
          });
        } else {
          reLoadImg();
          ElMessage({
            type: "error",
            message: "请完善必填信息后提交",
          });
        }
      });
    };

    const checkfileType = (file) => {
      const fileType = file.type;
      if (fileType.indexOf("image") == -1) {
        ElMessage({
          type: "error",
          message: "请上传正确的格式文件",
        });
        return false;
      }
      return true;
    };
    const data = reactive({
      viewModel,
      applyGuide,
      applyId,
      amount,
      imgCode,
      reLoadImg,
      formRef,
      imageDialog,
      validCompanyRegion,
      formRules,
      province,
      userCityList,
      userAreaList,
      companyCityList,
      companyAreaList,
      formData,
      changeView,
      getCity,
      getArea,
      toElList,
      toSubmit,
      onExceed,
      handlePictureCardPreview,
      onIdCardSuccess,
      onIdCardRemove,
      onCertificateSuccess,
      onCertificateRemove,
      onCaDigitalSuccess,
      onCaDigitalRemove,
      onSignatureSuccess,
      onSignatureRemove,
      submitForm,
      getHeaders,
      getFileUrl,
      downloadFile,
      checkfileType,
      checkPhone,
      checkTel,
    });
    onBeforeMount(() => {
      getProvince();
      reLoadImg();
    });
    return {
      ...data,
      cardUpload,
    };
  },
};
</script>

<style scoped>
@import url("./CaApplyForm.css");
</style>
