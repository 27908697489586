import request from "@/util/axios"
import {
  getHeaders
} from "@/util";


const checkCanApply = () => {
  return request.request({
    url: '/api/ca/caApply/checkCanApply',
    headers: getHeaders(),
    method: 'GET'
  })
}

const getApplyGuide = () => {
  return request.request({
    url: '/api/ca/caApplyGuide/detail',
    method: 'GET',
  })
};
const getRegion = (params) => {
  return request.request({
    url: '/api/blade-system/region/lazy-tree',
    method: 'GET',
    params
  })
};
const downloadFile = (url, fileName) => {
  const x = new XMLHttpRequest()
  x.open("GET", url, true)
  x.responseType = 'blob'
  x.onload = () => {
    const url = window.URL.createObjectURL(x.response)
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.download = fileName
    a.click()
    a.remove()
  }
  x.send()
}
const saveApply = (params) => {
  return request.request({
    url: '/api/ca/caApply/save',
    method: 'POST',
    data: params
  })
};
const pay = (params) => {
  return request.request({
    url: '/api/ca/caApply/pay',
    method: 'POST',
    data: params
  })
};
const checkOrderStatus = (params) => {
  return request({
    url: '/api/blade-pay/order/checkOrderStatus',
    method: 'GET',
    params
  })
};


export {
  getApplyGuide,
  getRegion,
  downloadFile,
  saveApply,
  pay,
  checkOrderStatus,
  checkCanApply
}