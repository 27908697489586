<template>
  <BasePage
    :bannerUrl="BiddingbannerImg"
    subTilte="南通市海衡招标有限公司欢迎您"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
    :hideLeft="true"
  >
    <CaApplyGuide v-if="viewModel === 0" />
    <CaApplyForm v-if="viewModel === 1" />
    <CaApplyPay v-if="viewModel === 2" />
  </BasePage>
</template>

<script >
import { ref, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import imgData from "@/data";
import { caApplyStore } from "@/store";
import BasePage from "@/components/BasePage/BasePage";
import CaApplyGuide from "./components/CaApplyGuide/CaApplyGuide";
import CaApplyForm from "./components/CaApplyForm/CaApplyForm";
import CaApplyPay from "./components/CaApplyPay/CaApplyPay";

export default {
  components: { BasePage, CaApplyGuide, CaApplyForm, CaApplyPay },
  setup() {
    const backtop = ref(null);
    const { BiddingbannerImg } = reactive(imgData);
    const { viewModel } = storeToRefs(caApplyStore());
    watch(viewModel, () => {
      // 切换页面的时候回到顶部
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    });
    return {
      backtop,
      BiddingbannerImg,
      viewModel,
    };
  },
};
</script>


<style scoped>
</style>

